import * as React from 'react';
import { ApiResponse } from 'libs/utils/api/types';
import { Catalog, LineItem, Error, MaterialInventory } from 'api/persephone/persephone';

export interface PersephoneContextType {
  doQuoteRequest: (data, productionDays, options?) => ApiResponse<LineItem, Error>;
  catalog: Catalog | null;
  materialInventory: MaterialInventory[];
  available: boolean;
}

const PersephoneContext = React.createContext<PersephoneContextType>(null);

export default PersephoneContext;
