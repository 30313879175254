import apiCall from 'libs/utils/api/apiCall';
import queryString from 'qs';
import { Product } from './productTypes';
import { ApiResponse, SharedErrors } from 'libs/utils/api/types';
import { ConsumedMaterial, PrintingSpec } from 'api/admin/orders/types';
import { BasicResponse } from 'api/types';

export interface Catalog {
  [key: string]: Product;
}

export interface MaterialInventory {
  quantity: number;
  materialInventoryKey: string;
  materialInventoryName: string;
  materialKey: string;
  materialName: string;
  materialShortName: string;
  weight: number;
  cost: number;
  width: string;
  height: string;
  categoryKey: string;
  categoryName: string;
}

export const doCatalogRequest = (endpoint, token): ApiResponse<Catalog, SharedErrors> =>
  apiCall.get<Catalog, SharedErrors>({
    url: `${endpoint}/instant_quoter/get_product_catalog?${queryString.stringify({
      token,
    })}`,
    remote: true,
  });

export const doMaterialInventoryRequest = (endpoint, token): ApiResponse<MaterialInventory[], SharedErrors> =>
  apiCall.get<MaterialInventory[], SharedErrors>({
    url: `${endpoint}/materials-api/material-inventory-list?${queryString.stringify({
      token,
    })}`,
    remote: true,
  });

export interface LineItem {
  lineItem: {
    total: string;
    productionRange: string;
    weight?: string;
    cost?: string;
    description?: string;
    printingSpecsAttributes?: PrintingSpec[];
    consumedMaterialsAttributes?: ConsumedMaterial[];
  };
}

export interface Error {
  message: string;
  status: string;
  data?: {
    type: 'minRequirementsError' | 'CustomQuoteError';
    total: number;
  };
}

export const doQuoteRequest = (
  endpoint,
  token,
  values,
  productionDays,
  options,
): ApiResponse<LineItem, Error> =>
  apiCall.get<LineItem, Error>({
    url: `${endpoint}/calculate/line_item?${queryString.stringify(
      {
        quoter_json: JSON.stringify(values),
        production_days: productionDays,
        token,
        optional_attributes: options,
      },
      { arrayFormat: 'brackets' },
    )}`,
    remote: true,
  });

export const submitQuoteRequest = (
  orderNumber,
  userId,
  productKey,
  values,
  url,
  price,
): ApiResponse<BasicResponse, Error> =>
  apiCall.post<BasicResponse, Error>({
    url: '/line_item_quotes',
    data: {
      orderNumber,
      lineItemQuote: { userId, productKey, referring_url: url, quoterJson: values, price },
    },
    unscoped: true,
  });
