import * as React from 'react';
import css from './RushCriticalModal.scss';
import Modal from 'styleguide/components/Modal/Modal';
import { H2 } from 'styleguide/components/Heading';
import Span from 'styleguide/components/Span/Span';
import { contactPhone } from 'app/constants';
import { Button } from 'styleguide/components';

const RushCriticalModal = ({ onClose }) => (
  <Modal
    onClose={onClose}
    shown
    className={css.modal}
    title={<H2 className={css.heading}>Call for a Rush Critical quote</H2>}
  >
    <div data-cy="rushCriticalModal" className={css.content}>
      <Span className={css.lastMinute}>LAST MINUTE ORDERING? NEED A MIRACLE?</Span>
      <Span>We may be able to help. Call us immediately and ask for a Printivity Rush Critical.</Span>
      <Span>{`Call ${contactPhone}.`}</Span>

      <Button
        dataCy="rushCriticalModalOkBtn"
        type="button"
        color="blue"
        className={css.button}
        onClick={onClose}
      >
        OK
      </Button>
    </div>
  </Modal>
);

export default RushCriticalModal;
