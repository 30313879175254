import * as React from 'react';
import cn from 'classnames';
import css from './ProductLine.scss';
import Grid from 'styleguide/components/Grid/Grid';
import { LineItem, Order } from 'api/orders/types';
import { formatMoney } from 'utils/money';
import { Button, LinkLike } from 'styleguide/components';
import IfTabletOrMobile from 'styleguide/components/Conditional/IfTabletOrMobile';
import Title from '../Title/Title';
import ProductDescription from '../ProductDescription/ProductDescription';
import A from 'styleguide/components/Links/A';
import IfDesktop from 'styleguide/components/Conditional/IfDesktop';

interface Props {
  disabled: boolean;
  order: Order;
  lineItem: LineItem;
  lineItemTotal: number;
  onRemoveClick?: (id: number) => void;
  onEditClick?: (id: number) => void;
  onUploadClick?: (id: number) => void;
  canEdit: boolean;
  canUpload: boolean;
  isLoading: boolean;
  deliveryDate: string;
}

const ProductLine = ({ lineItem, lineItemTotal, ...props }: Props) => (
  <Grid.Container className={css.productLine}>
    <Grid noPadding>
      <Grid.Row className={css.row}>
        <Grid.Col className={css.col} sm={12} lg={props.canUpload ? 5 : 8}>
          <IfTabletOrMobile>
            <Title className={css.mobileTitle}>Item</Title>
          </IfTabletOrMobile>
          <ProductDescription lineItem={lineItem} deliveryDate={props.deliveryDate} order={props.order} />
          {!props.isLoading && (
            <div className="mt-2.5 space-x-1">
              {props.canEdit && (
                <LinkLike
                  data-cy={`removeLineItem-${lineItem.id}`}
                  className={css.removeLink}
                  underline="none"
                  color="blue"
                  onClick={() => props.onRemoveClick(lineItem.id)}
                  disabled={props.disabled}
                >
                  Remove
                </LinkLike>
              )}
              {props.canEdit && (
                <LinkLike
                  data-cy={`editLineItem-${lineItem.id}`}
                  className={css.editLink}
                  underline="none"
                  color="blue"
                  onClick={() => props.onEditClick(lineItem.id)}
                  disabled={props.disabled}
                >
                  Edit
                </LinkLike>
              )}
            </div>
          )}
        </Grid.Col>
        <Grid.Col sm={4} lg={2} className={cn(css.productLineItemSize, css.quantityCol, css.col)}>
          <IfTabletOrMobile className={css.mobileHeader}>
            <Title className={css.mobileTitle}>Quantity</Title>
            <div data-cy={`lineItemQuantity-${lineItem.id}`} className="paragraph-light-mobile">
              {lineItem.quantity}
            </div>
          </IfTabletOrMobile>
          <IfDesktop>
            <div data-cy={`lineItemQuantity-${lineItem.id}`} className="paragraph-light-mobile">
              {lineItem.quantity}
            </div>
          </IfDesktop>
        </Grid.Col>
        <Grid.Col lg={2} sm={8} className={cn(css.productLineItemSize, css.priceCol, css.col)}>
          <IfTabletOrMobile className={css.mobileHeader}>
            <Title className={css.mobileTitle}>Price</Title>
            <div data-cy={`lineItemPrice-${lineItem.id}`} className="paragraph-light-mobile">
              {formatMoney(lineItemTotal)}
            </div>
          </IfTabletOrMobile>
          <IfDesktop>
            <div data-cy={`lineItemPrice-${lineItem.id}`} className="paragraph-light-mobile">
              {formatMoney(lineItemTotal)}
            </div>
          </IfDesktop>
        </Grid.Col>
        {props.canUpload && (
          <Grid.Col className={cn(css.col, css.uploadCol)} md={12} lg={3}>
            <IfTabletOrMobile>
              <Title className={css.mobileTitle}>File upload</Title>
            </IfTabletOrMobile>
            {lineItem.fileVersions.map((fv, i) => (
              <>
                {(props.canUpload || fv.state === 'waiting_on_files' || fv.state === 'files_submitted') && (
                  <Button
                    dataCy={`lineItemUploadFilesBtn-${lineItem.id}`}
                    className={css.uploadButton}
                    disabled={props.disabled}
                    type="button"
                    color="blue"
                    onClick={() => props.onUploadClick(lineItem.id)}
                  >
                    {lineItem.fileVersions[i].uploads.length > 0 ? 'Add/Remove files' : 'Upload files'}
                  </Button>
                )}
                {lineItem.fileVersions[i].uploads.slice(0, 2).map(upload => (
                  <A
                    data-cy={`lineItemUploadedFile-${lineItem.id}-${upload.name}`}
                    className={cn(css.productLineItemSize, css.anchor)}
                    href={upload.url}
                    color="black"
                    underline="always"
                    targetBlank
                  >
                    {upload.name}
                  </A>
                ))}
                {lineItem.fileVersions[i].uploads.length >= 3 && (
                  <span
                    data-cy={`lineItemMoreFilesUploaded-${lineItem.id}`}
                    className={css.productLineItemSize}
                  >
                    ...and {lineItem.fileVersions[i].uploads.length - 2} more files
                  </span>
                )}
              </>
            ))}
          </Grid.Col>
        )}
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default ProductLine;
