export function clearLocalStorage(): void {
  localStorage.clear();
}

export function removeLocalStorage(key: string): void {
  localStorage.removeItem(key);
}

export function setLocalStorage<T>(key: string, value: T, replacer?: Array<string | number>): void {
  if (replacer) {
    localStorage.setItem(key, JSON.stringify(value, ['value', ...replacer]));
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

/* eslint-disable no-redeclare */
export function getLocalStorage<T>(key: string): T | null;
export function getLocalStorage<T>(key: string, other: T): T;
export function getLocalStorage<T>(key: string, other?: T): T | null {
  if (typeof window !== 'undefined') {
    const rawData: string = localStorage.getItem(key);
    let data: T = null;
    if (rawData !== null) {
      data = JSON.parse(rawData);
    } else if (other) {
      data = other;
    }
    return data;
  }
  return null;
}
/* eslint-enable no-redeclare */

export function getAllLocalStorageVars(): Record<string, string> {
  if (typeof window !== 'undefined') {
    const output = {};
    for (let i = 0; i < localStorage.length; i++) {
      const rawData = localStorage.getItem(localStorage.key(i));
      let data = null;
      if (rawData !== null) {
        try {
          try {
            data = JSON.parse(rawData);
          } catch {
            data = rawData;
          }
        } catch {
          output[localStorage.key(i)] = 'Error while parsing';
          continue;
        }
      }
      output[localStorage.key(i)] = data;
    }
    return output;
  }
  return {};
}

export function getAndInitLocalStorage<T>(key: string, value: T, replacer?: Array<string | null>): T | null {
  let v: T = getLocalStorage(key);
  if (!v) {
    setLocalStorage(key, value, replacer);
    v = getLocalStorage(key);
  }
  return v;
}
