import * as React from 'react';
import cn from 'classnames';
import css from './Header.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Title from '../Title/Title';

interface Props {
  hideUpload?: boolean;
  noPaddingTop?: boolean;
  dataCy: string;
}

const Header = ({ hideUpload, noPaddingTop, dataCy }: Props) => (
  <Grid.Container
    data-cy={dataCy}
    className={cn(css.header, { [css[`header--noPaddingTop`]]: !!noPaddingTop })}
  >
    <Grid noPadding>
      <Grid.Row>
        <Grid.Col md={hideUpload ? 8 : 5}>
          <Title>Item</Title>
        </Grid.Col>
        <Grid.Col md={hideUpload ? 2 : 2} className={css.quantity}>
          <Title>Quantity</Title>
        </Grid.Col>
        <Grid.Col md={2} className={css.price}>
          <Title>Price</Title>
        </Grid.Col>
        {!hideUpload && (
          <Grid.Col md={3} className={css.upload}>
            <Title>File upload</Title>
          </Grid.Col>
        )}
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default Header;
