import * as React from 'react';
import ItemRow from './ItemRow';
import { Order } from 'app/api/orders/types';
import { UpdateOrderForNewArrivalResponse } from 'app/api/simplyShip';
import { formatMoney } from 'app/utils/money';

interface DiscountRowProps {
  order: Order;
  orderTotals: UpdateOrderForNewArrivalResponse;
  onAddPromocodeClick: () => void;
  hasPromotionsApplied: boolean;
  disabled: boolean;
}

const DiscountRow = ({
  order,
  orderTotals,
  onAddPromocodeClick,
  disabled,
  hasPromotionsApplied,
}: DiscountRowProps) => {
  const getValue = () => {
    const promotionTotal = orderTotals?.promotionTotal || order.promotionTotal;
    if (promotionTotal && +promotionTotal !== 0) {
      return formatMoney(promotionTotal);
    }
    if (hasPromotionsApplied) {
      return '-';
    }
    return (
      <div
        onClick={onAddPromocodeClick}
        onKeyDown={onAddPromocodeClick}
        className="!m-0 cursor-pointer !p-0 text-sm text-blue underline"
        role="button"
        tabIndex={0}
        data-cy="showPromoInputsBtn"
        aria-disabled={disabled}
      >
        Add Promo Code
      </div>
    );
  };

  return (
    <ItemRow title="Discount" className="mb-6" valueDataCy="summaryDiscount" value={getValue()} noBorder />
  );
};

export default DiscountRow;
