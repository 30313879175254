import * as React from 'react';
import cn from 'classnames';
import css from './NeedHelpSection.scss';
import Grid from 'styleguide/components/Grid/Grid';
import H5 from 'styleguide/components/Heading/H5';

interface Props {
  className?: string;
}

const NeedHelpSection = ({ className }: Props) => (
  <Grid.Container className={cn(css.container, className, '!mb-3 max-w-sm -md:max-w-full')}>
    <Grid.Row className={cn(css.row)}>
      <Grid.Col className={cn(css.column)} sm={12} md={12}>
        <H5 className={cn(css.needHelpText)}>Need help? We’re here.</H5>
        <p data-cy="needHelpSection" className={cn(css.giveCallText)}>
          Give us a call at 1-877-649-5463
        </p>
      </Grid.Col>
    </Grid.Row>
  </Grid.Container>
);

export default NeedHelpSection;
