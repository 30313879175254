import * as React from 'react';
import { User } from 'app/contexts/UserContextContainer/types';

export interface YvantageUserContextType {
  logIn: (user: User) => void;
  logOut: () => void;
  currentYvUser: User | null;
}

const YvantageUserContext = React.createContext<YvantageUserContextType>(null);

export default YvantageUserContext;
